import React from "react";
import "./BookingAddressComponent.scss";
import { connect } from "react-redux";
import { ApplicationState } from '../../../appState';
import { GeoPoint } from "../../Location/Entities";
import { getContentUrl, ContentURL } from '../../Utils/ContentURL';
import { PickupAddressStatus } from "../PickupAddressStatus";
import { ServiceCheckStatus, PickupServiceCheckState } from "../BookingEntities";
import { SimpleUserProfile } from "../../User/ProfileEntitiesV2";
import { MapView } from "../../../widgets/NavBar/TabEntities";
import { Dispatch } from "../../Dispatch";
import { ConvertToPlaceResult, ApplyPickupLocationSelection, ApplyDropoffLocationSelection } from '../BookingLocationHelper';
import { BookingWidgetModeKind } from '../../BookingTemplate/BookingTemplateEntities';
import { VehicleOption } from "../../Condition/Redux/ConditionEntities";
import { AddressStatusType, BookingFormKind } from "../../UILogicControl/UILogicControlEntities";
import { GetBrandedUrl, BrandedImage } from "../../Utils/BrandedContentUrls";
import { CustomErrorMessages, WellKnownMessageKind } from "../../Utils/ErrorMessages";
import { AddressV2 } from "../../../Services/MakeBookingContracts";
import { GoogleAddressPicker } from "../../AddressPicker/Components/GoogleAddressPicker";
import { LocationTypes } from "../../AddressPicker/Entities/PlaceSearchEntities";
import { AddressEntryKind, AddressPickerEntry } from "../../AddressPicker/Entities/AddressPickerEntry";
import { ConsiderClearingDriverNotesFromFavourite } from "../ConsiderClearingDriverNotesFromFavourite";
import { BookingLocationInfo } from "../Redux/BookingState";
import { IconButton } from "@mui/material";
import Close from "@mui/icons-material/Close";
import { ConsiderFareUpdate } from "../../Condition/FareLoader";
import { LoadCuratedLocations } from "../../CuratedLocations/CuratedLocations";

interface PropsFromStore {
    UserProfile : SimpleUserProfile | undefined,
    BookOnAccount: boolean,
    locationVicinity: GeoPoint;
    PickupServiceCheckStatus: string;
    IsBookingFormStrictValidationModeOn: boolean;

    /** Low level UI state (focused, valid) for the pickup address */
    PickupUiState: AddressStatusType;

    /** Low level UI state (focused, valid) for the dropoff address */
    DropoffUiState: AddressStatusType;
    SelectedVehicle: VehicleOption,
    BookingWidgetMode: BookingWidgetModeKind;
    PickupServiceCheck : PickupServiceCheckState;
    PickupV2: AddressV2 | null;

    ActiveBookingForm: BookingFormKind;
    Locations: BookingLocationInfo[];

    /** If any address input is being dragged currently. */
    IsDraggingAnAddress: boolean;
}

interface AddressProps { 
    IsPickup: boolean;

    /** Index of the address in the booking locations list */
    AddressIndex: number;
}

class BookingAddressComponent extends React.Component<PropsFromStore & AddressProps> {
    constructor(props: PropsFromStore & AddressProps) {
        super(props);
    }

    /** Quick clear */
    onClearEvent = () => {   
        if (this.props.IsPickup) {
            Dispatch.GoogleMap.PickupCleared();
            Dispatch.Booking.ClearAddress(0);

            Dispatch.Booking.PickupServiceability({
                PickupPlaceId: "",
                ServiceabilityCheckState: {
                    status: ServiceCheckStatus.NoInputSelected
                }
            });

            Dispatch.UILogicControl.OnDoesPickupInputHaveValueChange(false);
            Dispatch.UILogicControl.ValidateVehicleOnPickupChange(true);
            ConsiderClearingDriverNotesFromFavourite(true);
            Dispatch.Booking.ClearFavouriteAddress(0);
            Dispatch.Booking.ResetFromCuratedLocation(0);
        } else {
            Dispatch.GoogleMap.DropoffCleared();
            Dispatch.Booking.ClearAddress(1);
            Dispatch.UILogicControl.OnDoesDropoffInputHaveValueChange(false);
            ConsiderClearingDriverNotesFromFavourite(false);
            Dispatch.Booking.ClearFavouriteAddress(1);
            Dispatch.Booking.ResetFromCuratedLocation(1);
        }
        Dispatch.Condition.ClearFareEstimate();
    }

    onPlaceSelected = async (place: google.maps.places.PlaceResult, placeText: string, addressEntry: AddressPickerEntry) => {

        // new Google typings have raised the possibility of getting blanks from these values
        // these are places we cannot handle
        if (!place.geometry) return;
        if (!place.geometry.location) return;
        if (!place.place_id) return;

        const placeResult = ConvertToPlaceResult(place, placeText);

        if (this.props.IsPickup) {
            // Remove not valid error message if the user enters an address.
            Dispatch.UILogicControl.SetPickupValidity(true);
            ApplyPickupLocationSelection(placeResult);

            //as soon as an address is selected from the dropdown (either from autocomplete or favourites), we reset 'FromCuratedLocation' flag if it was set previously.
            Dispatch.Booking.ResetFromCuratedLocation(0);

            // Update or clear existing booking favourite address based on the AddressEntry type.
            if (addressEntry.Kind === AddressEntryKind.GoogleMaps) {
                ConsiderClearingDriverNotesFromFavourite(true);
                Dispatch.Booking.ClearFavouriteAddress(0);
            }
            else {
                Dispatch.Booking.FavouriteAddress(0,  addressEntry.Favourite);

                if (addressEntry.Favourite.PickupNotes) {
                    Dispatch.Booking.DriverNotes(0, addressEntry.Favourite.PickupNotes);
                }
            }

            // call the curated locations API here. We don't need to call that if the location is not serviceable. that's why we call it after 'ApplyPickupLocationSelection'. Also calling it after favourite address operations (e.g. loading of driver notes) so we don't lose the additional data from favourites.
            Dispatch.CuratedLocations.CurrentlyActiveLocationIndex(0);
            await LoadCuratedLocations(this.props.AddressIndex);

        } else {
            Dispatch.UILogicControl.SetDropoffValidity(true);
            ApplyDropoffLocationSelection(placeResult, this.props.AddressIndex);

            // as soon as an address is selected from the dropdown (either from autocomplete or favourites), we reset 'FromCuratedLocation' flag if it was set previously.
            Dispatch.Booking.ResetFromCuratedLocation(this.props.AddressIndex);

            if (addressEntry.Kind === AddressEntryKind.GoogleMaps) {                
                ConsiderClearingDriverNotesFromFavourite(false);
                Dispatch.Booking.ClearFavouriteAddress(this.props.AddressIndex);
            }
            else {
                Dispatch.Booking.FavouriteAddress(this.props.AddressIndex,  addressEntry.Favourite);
                if (addressEntry.Favourite.DropoffNotes) {
                    Dispatch.Booking.DriverNotes(this.props.AddressIndex, addressEntry.Favourite.DropoffNotes);
                }
            }

            Dispatch.CuratedLocations.CurrentlyActiveLocationIndex(this.props.AddressIndex);
            await LoadCuratedLocations(this.props.AddressIndex);
        }        
    }    
    
    IsPickUpInputValid = () => {  

        const { PickupV2, BookingWidgetMode, PickupServiceCheckStatus, IsBookingFormStrictValidationModeOn, PickupUiState } = this.props;
        
        // Should select serviceable address
        if (PickupServiceCheckStatus === ServiceCheckStatus.KnownBad) return false;

        // Address can't be populated from an existing booking
        if (!PickupUiState.IsValid) return false;

        // Validate in strict validation mode
        if (!IsBookingFormStrictValidationModeOn) return true; 
        
        // Do not validate, if pickup input is focused
        if (PickupUiState.IsFocus) return true;
        
        // Validate input value for template booking
        if (BookingWidgetMode !== BookingWidgetModeKind.Booking) {
            
            // Invalid state if input is entered but, no address is selected
            if (PickupUiState.DoesInputHaveValue && !PickupV2) return false;            
        }
        else {
            // Pickup address is mandatory for booking only
            if (PickupServiceCheckStatus !== ServiceCheckStatus.KnownGood) return false;
        }       
                               
        return true;
    }
     
    IsDropOffInputValid = () => {

        const { IsBookingFormStrictValidationModeOn, BookingWidgetMode, DropoffUiState } = this.props;

        // Cannot populate from an existing booking
        if (!DropoffUiState.IsValid) return false;

        // Validate in strict validation mode
        if (!IsBookingFormStrictValidationModeOn) return true;
        
        // Do not validate, if dropoff input is focused
        if (DropoffUiState.IsFocus) return true;
        
        // Valid Dropoff is selected. the first condition checks if the input field has not been removed from the UI.
        if (this.props.Locations[this.props.AddressIndex] && this.props.Locations[this.props.AddressIndex].Address) return true;
        
        // Validate input value for template booking
        if (BookingWidgetMode !== BookingWidgetModeKind.Booking) {
            
            // Invalid state if the input is entered but, address is not selected
            if (!DropoffUiState.DoesInputHaveValue) return true;
        }

        return false;
    }

     /**
     * There are only 2 situations show message:
     * 1> No pick when strict validation mode === true;
     * 2> Not enough details for pickup;
     */
    decidePickupErrorMessage = () => {

        const { PickupServiceCheck, IsBookingFormStrictValidationModeOn, BookingWidgetMode, PickupUiState } = this.props;
        
        // In strict validation mode, error message is displayed if Pickup address is empty or invalid
        if (IsBookingFormStrictValidationModeOn) {
            
            const noPickupSelected = PickupServiceCheck.status === ServiceCheckStatus.NoInputSelected;

            // Validate input value for template booking
            if (BookingWidgetMode !== BookingWidgetModeKind.Booking) {

                const isPickupInvalid = !PickupUiState.IsFocus && PickupUiState.DoesInputHaveValue && noPickupSelected;

                // Invalid pickup address if input is entered but, no address is selected
                if (isPickupInvalid) return CustomErrorMessages.NoPickup;
                
                return "";// All good
            }

            if(noPickupSelected) return CustomErrorMessages.NoPickup;   
        }

        if (PickupServiceCheck.status === ServiceCheckStatus.KnownBad && PickupServiceCheck.errorMessage.ProblemText === WellKnownMessageKind.StreetNameUnavailable.ProblemText && PickupServiceCheck.isPickupErrorMessageShown)
            return PickupServiceCheck.errorMessage.SolutionText;

        // Pickup address can't be populated from history/favourite
        if (!PickupUiState.IsValid) return CustomErrorMessages.NoPickup;

        return ""; // Valid
    }

    decideDropoffErrorMessage = () => {

        const { IsBookingFormStrictValidationModeOn, BookingWidgetMode, DropoffUiState } = this.props; 

         /** In strict validation mode, error message is displayed if any of the below conditions are satisfied:
         * 1) When booking on accounts, Dropoff address is empty or invalid
         * 2) For parcel booking, Dropoff address is empty or invalid
         */
        if (IsBookingFormStrictValidationModeOn && this.props.Locations[this.props.AddressIndex]) {

            const dropoffAddress = this.props.Locations[this.props.AddressIndex].Address;
            
            // Validate input value for template booking
            if (BookingWidgetMode !== BookingWidgetModeKind.Booking) {

                const isDropoffInvalid = !DropoffUiState.IsFocus && DropoffUiState.DoesInputHaveValue && !dropoffAddress;

                // Invalid dropoff address if input is entered but, no address is selected
                if (isDropoffInvalid) return CustomErrorMessages.NoDropoff;

                return "";// All good
            }

            if (!dropoffAddress) return CustomErrorMessages.NoDropoff;
        }
        
        // Dropoff address can't be populated from history/favourite
        if (!DropoffUiState.IsValid) return CustomErrorMessages.NoDropoff;

        return "";// All good
    }

    /** Delete the entire address field, not just clearing the address and keeping the input field. */
    onDeleteEvent = () => {
        Dispatch.Booking.RemoveLocation(this.props.AddressIndex);
        ConsiderFareUpdate();
    }

    /** Change to MapView on click of the field or on start of entering value. */
    changeView = () => Dispatch.Tab.SelectItem(MapView);

    render() {
        const isBookingOnAccount = this.props.UserProfile != null && this.props.BookOnAccount;
        
        const isBookingModeOn = this.props.BookingWidgetMode === BookingWidgetModeKind.Booking;
        
        /**
         * Set focus on Pickup address input when,
         * 1. Not booking on accounts
         * 2. Not on Booking template mode
         */
        const allowFocus = isBookingModeOn && !isBookingOnAccount && this.props.IsPickup;

        const errorMessage = this.props.IsPickup ? this.decidePickupErrorMessage() : this.decideDropoffErrorMessage();

        // Drop-off (default)
        const placeText = this.props.Locations[this.props.AddressIndex] ? CalculateAddressDisplayText(this.props.Locations[this.props.AddressIndex].Address) : "";
        let addressIcon = this.props.AddressIndex === (this.props.Locations.length - 1) ? getContentUrl(ContentURL.images.MyBookings.DestinationIcon) : getContentUrl(ContentURL.images.MyBookings.DropoffIcon);
        let labelText = this.props.ActiveBookingForm === BookingFormKind.PassengerBooking ? "Add destination (required)" : "Add dropoff (required)";
        let isAddressInvalid = !this.IsDropOffInputValid();

        if (this.props.IsPickup) {
            labelText = "Add pickup (required)";

            isAddressInvalid = !this.IsPickUpInputValid();
            addressIcon = GetBrandedUrl(BrandedImage.PickupAddressA);
        }

        const isMultiStopDropoff = this.props.Locations.length > 2 && !this.props.IsPickup;
        const startIconClass = this.props.IsDraggingAnAddress ? "hide-icons" : "";

        return (
            <div className="booking-fields-panel">
                <div className="booking-address-field" onClick={this.changeView} onKeyDown={this.changeView}>
                    <div className="inline-address-components">
                        <div className={startIconClass}>
                            <span className="start-icon"><img src={addressIcon}></img></span>
                        </div>
                        <div className={startIconClass}>
                            {this.props.AddressIndex !== (this.props.Locations.length - 1) && <img src={getContentUrl(ContentURL.images.address.Connector)} className="address-connector-line"></img>}
                        </div>
                        <GoogleAddressPicker
                            LocationType={LocationTypes.AddressStrict}
                            OnPlaceSelected={this.onPlaceSelected}
                            LabelText={labelText}
                            PreferNearbyTo={this.props.locationVicinity}
                            SpecifiedPlaceText={placeText}
                            OnCleared={this.onClearEvent}
                            StartIconUrl={addressIcon}
                            IsSelectedAddressInvalid={isAddressInvalid}
                            AutoFocus={allowFocus}
                            IncludeFavouriteAddresses={true}
                            HideClearButton={isMultiStopDropoff}
                            IsDraggable={isMultiStopDropoff}
                        />
                        {isMultiStopDropoff && <IconButton onClick={this.onDeleteEvent} size="large">
                            <Close fontSize="small" />
                        </IconButton>}
                    </div>                    
                
                    { this.props.IsPickup && <PickupAddressStatus/> }                
                    { errorMessage && <div className="booking-address-message">{errorMessage}</div> }
                </div>
            </div>
        );
    }
}

function mapStateToProps(state: ApplicationState): PropsFromStore {
    return {
        UserProfile: state.authentication.UserProfile,
        BookOnAccount: state.booking.IsOnAccount,
        locationVicinity: state.location.reliableLocation.value.geoPoint,
        PickupServiceCheckStatus: state.booking.PickupServiceCheck.status,
        IsBookingFormStrictValidationModeOn: state.uiLogicControl.BookingForm.IsStrictValidationModeOn,
        PickupV2: state.booking.Locations[0].Address,
        PickupUiState: state.uiLogicControl.AddressStatus.Pickup,
        DropoffUiState: state.uiLogicControl.AddressStatus.Dropoff,
        SelectedVehicle: state.condition.SelectedCondition,
        BookingWidgetMode: state.uiLogicControl.BookingForm.BookingWidgetMode,
        PickupServiceCheck: state.booking.PickupServiceCheck,
        ActiveBookingForm: state.uiLogicControl.BookingForm.ActiveBookingForm,
        Locations: state.booking.Locations,
        IsDraggingAnAddress: state.uiLogicControl.BookingForm.IsDraggingAnAddress,
    };
}

function CalculateAddressDisplayText(address: AddressV2 | null): string {

    if (!address) return "";

    if (!address.FullTextAddress) return "";

    return address.FullTextAddress;
}

export default connect(mapStateToProps)(BookingAddressComponent);