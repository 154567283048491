import { Api } from "../../Services/Api";
import { LocationZoneQuery } from "../../Services/LocationsEntitiesV2";
import { AddressV2 } from "../../Services/MakeBookingContracts";
import appstore from "../../appStore";
import { DialogKind } from "../Dialog/DialogEntities";
import { Dispatch } from "../Dispatch";

/** Call the API to load curated locations and store the result in redux. Then open the location selector popup if necessary. */
export async function LoadCuratedLocations(locationIndex: number) {

	const currentLocationAddress = appstore.getState().booking.Locations[locationIndex].Address!;

	const request: LocationZoneQuery = {
		Latitude: currentLocationAddress.GeoLocation.Latitude,
		Longitude: currentLocationAddress.GeoLocation.Longitude,
		GooglePlaceId: currentLocationAddress.GoogleMapsPlaceId,
		IsPickup: locationIndex === 0
	}

	const result = await Api.LocationV2.QueryCuratedLocation(request);

	if (!result.isSuccess) {
		return;
	}

	if (result.value.IsLocationZone) {
		Dispatch.CuratedLocations.LocationSuggestions(result.value);

		// Open location selection dialog. But no need to select from the list again if IsExactLocation is true, so don't open in that case.
		if (!result.value.IsExactLocation) {
			Dispatch.Dialog.ShowDialog(DialogKind.CuratedLocations);
		}
		else {
			// we also replace lat/long values of the address with the ones from the curated location if IsExactLocation is true since the popup is not opened in this case (IsExactLocation becomes true when the GooglePlaceId of the request matches that of the curated location in the DB, but geolocation can be different because we sometimes override lat/long values to match a specific preferred location). these will be used for fare calculation and booking creation.
			let selectedAddress = appstore.getState().booking.Locations[locationIndex].Address!;

			const newAddress: AddressV2 = {
				...selectedAddress,
				GeoLocation: {
					Latitude: result.value.Addresses[0].Latitude,
					Longitude: result.value.Addresses[0].Longitude
				}
			};

			Dispatch.Booking.Address(locationIndex, newAddress);
			Dispatch.Booking.FromCuratedLocation(locationIndex);
		}
	}

	return;
}