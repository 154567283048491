import { DateTime } from "luxon";
import { ApplicationState } from "../../appState";
import appstore from "../../appStore";
import { FeatureFlags } from "../../Config/FeatureFlags";
import { BookingLocationV1, BookingPaymentV1, BookingRequestV1b, BookingTimingV1, ClientDetails, ConditionV1 } from "../../Services/BookingEntities";
import { ApiVehicleOption } from "../Condition/Redux/ConditionEntities";
import { ConsiderAddingSilverServiceCondition } from "../Condition/ShouldAddSilverServiceCondition";
import { CreditCardPlaceholder, PaymentOptionKind } from "../Payment/PaymentEntities";
import { ShouldIncludeCardIdInBooking } from "../Payment/PaymentHandler";
import { UILayoutMode } from "../UILogicControl/UILogicControlEntities";
import { ComputeDriverNotes, GetBestLocationContact, GetOverallBookingContact } from "./CreateBookingCommon";
import { ConvertToApiModel } from "./Parcel/ParcelEntities";

export function BuildBookingRequest(withSmsVerification: boolean): BookingRequestV1b {

    // Get data from the store, all booking information is guaranteed via validation before this step.
    const state = appstore.getState();
    const booking = state.booking;
    const condition = state.condition;
    const SelectedCondition = condition.SelectedCondition;

    // Evaluate booking time
    const timeV2 = booking.BookingTimeV2;

    // CAREFUL: [time] is really for backwards compatibility only.
    let time: string = DateTime.now().toISO();
    let localTime: string | null = null;

    if (timeV2.IsImmediate == false) {
        localTime = timeV2.RequestedDate.toISO();
        time = localTime;
    }

    const bookingLocations: BookingLocationV1[] = state.booking.Locations.map((location, index) => {
        return {
            PlaceId: location.Address!.GoogleMapsPlaceId,
            ContactDetails: GetBestLocationContact(location.Contact),
            DriverInstructions: index == 0 ? ComputeDriverNotes(booking) : location.DriverNotes,
            GeoLocation: location.FromCuratedLocation ? location.Address!.GeoLocation : null,
        }
    });

    const threeDs = state.payment.ThreeDSecure;

    const paymentInfo: BookingPaymentV1 = {
        DeviceData: booking.DeviceData ?? null,
        PaymentCardId: null,
        Fare: null,
        AccountInfo: null,
        PaymentPreAuthOptIn: ShouldBePreAuthorised(state),
        IsFixedFare: false,
        Upgraded3DsNonce: threeDs?.Upgraded3DsNonce ?? null,
        LiabilityShifted3Ds: threeDs?.LiabilityShifted3Ds ?? null,
        ReceiptEmail: state.GuestPayment.EmailForReceipt,
    };

    /** Send fixed fare details with every booking request if available. */
    if (SelectedCondition.FareDetails) {
        paymentInfo.Fare = {
            FixedFareId: SelectedCondition.FareDetails.EstimateId,
            FixedFarePartitionKey: SelectedCondition.FareDetails.EstimateId // this is no longer required by the booking API. sending this for now until the booking MGMT is updated to remove this field (with multi-stop booking creation work).
        };

        // Fixed Price is selected by default even if fare is not loaded. therefore make this true only if Fare details are available (and Fixed Price is still selected)
        paymentInfo.IsFixedFare = condition.IsPriceGuaranteeSelected;
    }

    if (ShouldIncludeCardIdInBooking(booking)) {
        paymentInfo.PaymentCardId = booking.PaymentOption?.Card?.CardId ?? null;
    }

    // no need to check the nullability of state.payment.ApplePay here. if Apple Pay is selected, create booking flow exits before this point if any error occurred during payment authorisation or adding payment method to MPS. If all succeeded, payment.ApplePay is non-null.
    if (booking.PaymentOption?.Type == "ApplePay") paymentInfo.PaymentCardId = state.payment.ApplePay!.CardId;

    // book with Google Pay
    if (booking.PaymentOption?.Type == "GooglePay") paymentInfo.PaymentCardId = state.payment.GooglePay!.CardId;

    // book with a credit/debit card as a guest
    if (booking.PaymentOption === CreditCardPlaceholder) paymentInfo.PaymentCardId = state.payment.GuestCreditCard!.CardId;

    const SelectedAccountData = appstore.getState().booking.AccountData;

    if (SelectedAccountData) {
        paymentInfo.AccountInfo = {
            AccountNumber: SelectedAccountData.SelectedAccount.AccountNumber,
            OrderNumber: SelectedAccountData.OrderNumber ?? null
        };
    }

    const timingInfo: BookingTimingV1 = {
        Time: time,
        LocalTime: localTime,
        IsImmediate: timeV2.IsImmediate
    };

    const clientInfo: ClientDetails = {
        IsMobile: state.uiLogicControl.LayoutMode === UILayoutMode.Mobile
    }

    const result: BookingRequestV1b = {
        Locations: bookingLocations,
        ContactInfo: GetOverallBookingContact(),
        Payment: paymentInfo,
        Pax: 0,
        Timing: timingInfo,
        Conditions: null,
        SmsChallengeId: null,
        Client: clientInfo,
        ParcelOptions: null,
        GoogleOdrdTripId: booking.GoogleOdrdTripId!, // not null here
        ClientCapabilities: {
            JustInTimePreauth: true
        }
    };

    // Parcel Delivery
    if (booking.DeliveryOption) {
        result.ParcelOptions = ConvertToApiModel(booking.DeliveryOption);
    }

    // SMS Verification
    if (withSmsVerification) {

        const smsChallengeId = appstore.getState().verification.SmsChallengeId!;
        result.SmsChallengeId = smsChallengeId;
    }

    /**
    * Condition check & setting
    */
    if (!!SelectedCondition.ApiVehicle) {
        const vehicleOptions: ApiVehicleOption[] = [SelectedCondition.ApiVehicle];

        ConsiderAddingSilverServiceCondition(vehicleOptions);

        const conditions = vehicleOptions.map(MapVehicleOptionToCondition);
        result.Conditions = conditions;
    }

    return result;
}

/** Decides whether to make PaymentPreAuthOptIn flag true or false. */
function ShouldBePreAuthorised(state: ApplicationState): boolean {

    if (!FeatureFlags.PreAuth) return false;

    // Fixed fare details is required for pre-auth bookings.
    if (!state.condition.SelectedCondition.FareDetails) return false;

    // Only applicable for CNP bookings.
    if (state.booking.PaymentOption?.Kind !== PaymentOptionKind.CardOrWallet) return false;

    return true;
}

/**
 * Convert the internal representation, ApiVehicleOption, to the V1-specific API data contract, ConditionV1.
 */
function MapVehicleOptionToCondition(vehicleOption: ApiVehicleOption): ConditionV1 {
    return {        
        ID: vehicleOption.ApiId
    };
}